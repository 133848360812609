import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spin } from "antd";
import * as actionTypes from "../constants";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { getUserBasic } from "../../User/actions";
import { useUrlQuery } from "../utils";
import { notify } from "../../../system/utils/notification";

export function ActivateUser({ userData, activateHook }) {
  const query = useUrlQuery();
  const dispatch = useDispatch();

  const [activate, { isLoading, isSuccess, error, data }] = activateHook({
    onSuccess: (res) => {
      if (res.data.status === "success" && res.data.access_token) {
        dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data });
        dispatch(getUserBasic({ user_id: res.data.user_id }));
      }
    },
    onError: (error: any) => {
      notify("error", error.response?.data?.message);
    },
  });

  useEffect(() => {
    if (userData) {
      activate(userData);
    }
  }, [userData]);

  return (
    <div className="h-100per fx-center fxDirCol text-center">
      {isLoading && (
        <Spin>{_t("Authentication in progress, please wait...")}</Spin>
      )}

      {data?.data.message && (
        <>
          👤 {query.get("email")}
          <br /> {_t(`${data?.data.message}`)}
        </>
      )}
      {data?.data.status === "success" && data?.data.access_token && (
        <Redirect to={APP_ROUTES.dashboard.general} />
      )}
      {error && !isSuccess && <>{_t("Something went wrong")}</>}
      {!userData && <>{_t("Url is not valid, please contact your admin")}</>}
    </div>
  );
}
