import React from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import NavCard from "./NavCard";
import config from "../../../system/config";
import { _t } from "../../Common/components/InjectIntlContext";

const DecorElements = () => {
  return (
    <div className="decor">
      {new Array(9).fill("").map((item, idx) => (
        <img
          src={`${config.imagesDirectory}/decor/decor-${idx + 1}.svg`}
          alt=""
          className="decor-item"
          key={idx}
        />
      ))}
    </div>
  );
};
const NavCards = () => {
  return (
    <section className="nav-cards">
      <div className="container">
        <div className="nav-cards-grid">
          <NavCard
            linkPath={_t("project_nav_link")}
            outerLink
            title="project_name"
            text="nav_card_text_1"
            className="nav-card"
          >
            <DecorElements />
            <div className="card-image">
              <img src={`${config.imagesDirectory}/nav-card-1.png`} alt="" />
            </div>
          </NavCard>
          <NavCard
            className="nav-card"
            title="Commissions"
            linkPath={APP_ROUTES.commissions.commission}
            text="nav_card_text_2"
          >
            <DecorElements />

            <div className="card-image">
              <img
                src={`${config.imagesDirectory}/nav-card-2.png`}
                alt=""
              />
            </div>
          </NavCard>
          <NavCard
            className="nav-card nav-card--news"
            title="news"
            linkPath={APP_ROUTES.news.list}
            text="nav_card_text_3"
          >
            <img
              src={`${config.imagesDirectory}/nav-card-3.jpg`}
              alt=""
            />
          </NavCard>
          <NavCard
            className="nav-card nav-card--contacts"
            title="contacts"
            linkPath={APP_ROUTES.contacts}
            text="nav_card_text_4"
          >
            <img
              src={`${config.imagesDirectory}/nav-card-4.jpg`}
              alt=""
            />
          </NavCard>
        </div>
      </div>
    </section>
  );
};

export default NavCards;
