import React from "react";
import classNames from "classnames";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { CurrencyLabel } from "../../../modules/Common/components";

type Type = {
  value_1: number;
  value_1_title: string;
  value_2: number;
  value_2_title: string;
  total: number;
  title?: string;
  isSports?: boolean;
  tableDetails?: boolean;
};
const CellTotals: React.FC<Type> = ({
  value_1,
  value_1_title,
  value_2,
  value_2_title,
  total,
  title,
  tableDetails,
}) => {
  const isDetailsShowed = tableDetails || value_1 > 0 || value_2 > 0;
  return (
    <div
      className={classNames("cell-partner-income", {
        "cell-partner-income--details": tableDetails,
      })}
    >
      <div className="cell-partner-income__total">
        {!tableDetails && (
          <p className="cell-partner-income__total-title">{_t(title)}</p>
        )}
        <p className="cell-partner-income__total-value">
          <CurrencyLabel value={total} />
        </p>
      </div>
      {isDetailsShowed && (
        <ul className="partner-income-details">
          {(tableDetails || value_1 > 0) && (
            <li className="partner-income-details__item">
              <p className="partner-income-details__title">
                {_t(value_1_title)}
              </p>
              <span className="partner-income-details__line"></span>
              <p className="partner-income-details__value">
                <CurrencyLabel value={value_1} />
              </p>
            </li>
          )}
          {(tableDetails || value_2 > 0) && (
            <li className="partner-income-details__item">
              <p className="partner-income-details__title">
                {_t(value_2_title)}
              </p>
              <span className="partner-income-details__line"></span>
              <p className="partner-income-details__value">
                <CurrencyLabel value={value_2} />
              </p>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CellTotals;
