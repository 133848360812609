import { useDispatch } from "react-redux";
import { signinAction } from "./actions";
import { useMutation } from "react-query";
import { getUserBasic } from "../User/actions";
import { notify } from "../../system/utils/notification";
import { getItem } from "../../system/helpers/localstorageHelper";
import { history } from "../../system/store";
import { APP_ROUTES } from "../../system/constants/constantsUrl";

export const useSignin = (_t) => {
  const successMessage = _t("success_login");
  const errorMessage = _t("check_credentials");
  const dispatch = useDispatch();
  return useMutation(
    (data: any) => {
      return dispatch(signinAction(data)).then((res) => {
        dispatch(getUserBasic({ user_id: res.payload.user_id }));
      });
    },
    {
      onSuccess: (data, variables) => {
        notify("success", successMessage);
        getItem("role") === "admin"
          ? history.push(APP_ROUTES.dashboard.admin)
          : history.push(APP_ROUTES.dashboard.general);
      },
      onError: (error) => {
        notify("success", errorMessage);
      },
    }
  );
};
