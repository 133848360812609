import React from "react";
import { Sidebar } from "./components";

const InnerPages = (props) => {
  return (
    <div className="dashboard-layout">
      <Sidebar />
      {props.children}
    </div>
  );
};

export default InnerPages;
