import {
  AffiliateGeoDetail,
  CountryItem,
  GamerProfileLink,
  PartnerProfileLink,
} from "../../../components";
import {
  currencyFields,
  renderCurrency,
} from "../../../../../../modules/Dashboard/constants";
import { CurrencyLabel } from "../../../../../../modules/Common/components";
import { CellSports, CellTotals } from "../../../../../../common/components";

// todo "add conditions for specific report"
export const renderCellValue = (key, text, record) => {
  const values = { text, record };
  const isCurrentKey = (value) => key === value;
  if (isCurrentKey("gamer_id")) return GamerProfileLink(values);
  if (isCurrentKey("partner_id")) return PartnerProfileLink(values);
  if (currencyFields.includes(key))
    return CurrencyLabel({
      value: text,
    });
  if (isCurrentKey("affiliate_geo_details"))
    return AffiliateGeoDetail(record.partner_id);
  if (isCurrentKey("country_full"))
    return CountryItem({
      code: record.country,
      name: record.country_full,
    });
  if (isCurrentKey("rd_percent"))
    return Number(text / 100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });

  if (isCurrentKey("ggr")) {
    return CellTotals({
      title: "ggr",
      total: record.ggr,
      value_1: record.ggr_casino,
      value_2: record.tax_casino,
      value_1_title: "ggr_casino",
      value_2_title: "tax_casino",
      tableDetails: true,
    });
  }
  if (isCurrentKey("sports")) {
    return CellSports({
      ...record,
    });
  }
  return text;
};
