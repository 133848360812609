import React, { useState } from "react";
import {
  PieChart as RPieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { useGamersChartData } from "../../apiHooks";
import { Spin, Empty, Select } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";
import { NoData } from "../../../Common/components/NoData";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const TooltipTemplate = ({ label, value, percent, color }) => {
  return (
    <div style={{ background: "white", padding: 5, border: "1px solid grey" }}>
      <div>
        <div
          style={{
            display: "inline-block",
            width: 5,
            height: 5,
            background: color,
            marginRight: 5,
          }}
        ></div>
        {label}
      </div>
      <div>
        {percent}% ({value})
      </div>
    </div>
  );
};

const renderLabel = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    index,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  const textRadius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const textX = cx + textRadius * Math.cos(-midAngle * RADIAN);
  const textY = cy + textRadius * Math.sin(-midAngle * RADIAN);

  const insideText = (
    <text
      x={textX}
      y={textY}
      fill="white"
      textAnchor={textX > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );

  if (percent < 0.05) {
    return null;
  }

  return (
    <g key={index}>
      {insideText}
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {payload.label}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const usePieChartData = (filters) => {
  const [chart, setChart] = useState(filters.chart);
  const { isLoading, data } = useGamersChartData({
    ...filters,
    chart: chart,
  });
  const chartData = data?.data;
  let total = 0;

  const convertedData = chartData?.map((d) => {
    const [label, value] = d;
    total = (total + (value as number)) as number;
    return {
      label,
      value,
    };
  });

  return {
    isLoading,
    items: convertedData,
    setChart,
    chart,
    total,
  };
};

export function PieChart({ filters }) {
  const { isLoading, items, chart, setChart, total } = usePieChartData(filters);

  return (
    <Spin spinning={isLoading}>
      <div
        key={chart}
        className="gamers-chart"
        style={{
          width: "100%",
          height: 500,
          background: "white",
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        <Select
          style={{ position: "absolute", left: 10, top: 10, zIndex: 1 }}
          onChange={setChart}
          value={chart}
        >
          <Select.Option value="geo">{_t("Gemers by geo")}</Select.Option>
          <Select.Option value="partner_income">
            {_t("Partner income")}
          </Select.Option>
          <Select.Option value="bets">{_t("Bets")}</Select.Option>
          <Select.Option value="ng">{_t("Net Gaming")}</Select.Option>
        </Select>
        {items?.length > 0 ? (
          <ResponsiveContainer>
            <RPieChart key={chart}>
              <Pie
                isAnimationActive={false}
                dataKey={"value"}
                data={items}
                labelLine={false}
                label={renderLabel}
                outerRadius={170}
                innerRadius={120}
                fill="#8884d8"
              >
                {items.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                content={(props) => {
                  if (!props.active) return null;
                  const [{ payload }] = props.payload;
                  const percent = ((payload.value / total) * 100).toFixed(2);

                  return (
                    <TooltipTemplate
                      label={payload.label}
                      percent={percent}
                      value={payload.value}
                      color={payload.fill}
                    />
                  );
                }}
              />
            </RPieChart>
          </ResponsiveContainer>
        ) : (
          !isLoading && <NoData style={{ height: "100%" }} />
        )}
      </div>
    </Spin>
  );
}
