import React from "react";
import classNames from "classnames";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import {
  removeItem,
  setItem,
} from "../../../../../system/helpers/localstorageHelper";

const AdminChatsNav = ({
  selectedList,
  setSelectedList,
  resetPage,
  setChatFilters,
}) => {
  const items = (_t: any) => [
    {
      title: _t("inbox"),
      value: "inbox",
    },
    {
      title: _t("outbox"),
      value: "outbox",
    },
    {
      title: _t("archive"),
      value: "archive",
    },
  ];
  const navItems = items(_t);

  const handlePaginationChange = (value) => {
    setItem("adminChatsTab", value);
    removeItem("adminChatsFilters");
    setChatFilters({});
    setSelectedList(value);
    resetPage(1);
  };
  return (
    <ul className="chats-nav">
      {navItems.map((item, idx) => (
        <li className="chats-nav__item" key={item.title}>
          <button
            className={classNames("chats-nav__button", {
              active: selectedList === item.value,
            })}
            onClick={() => handlePaginationChange(item.value)}
          >
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default AdminChatsNav;
