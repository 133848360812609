import React, { useEffect, useState } from "react";
import { Card, Row, Col, List, Divider } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { SimpleText } from "./inputs/SimpleText";
import { CollapsableInput } from "./inputs/CollapsableInput";
import { useGetRolesList, useUpdateProfile } from "../apiHooks";
import { CollapsableRadio } from "./inputs/CollapsableRadio";
import { CollapsableTextArea } from "./inputs/CollapsableTextArea";
import {
  DISABLED_ENABLED_RADIO,
  LANGUAGES_RADIO,
  STATUSES_RADIO,
  YES_NO_RADIO,
  ProfileBasicInformation,
} from "../constants";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import CopyToClipboard from "../../Common/components/CopyToClipboard";
import { Link, generatePath } from "react-router-dom";

const messages = (_t: any) => ({
  success: _t("Successfully updated"),
});

const FullWidthRow = ({ title, value }) => {
  return (
    <List.Item>
      <Row gutter={[10, 0]} style={{ width: "100%" }}>
        <Col span={8}>{title}</Col>
        <Col span={16}>{value}</Col>
      </Row>
    </List.Item>
  );
};

export const ProfileBasicInformationWidget = ({
  user,
  user_settings,
  user_types,
  cash_types,
  traffic_types,
  ex_ussr,
  projects,
  reset_link,
  remain_password_key,
  managers,
}: ProfileBasicInformation) => {
  const [submit, { isLoading, isSuccess }] = useUpdateProfile();
  const [editedField, setEditedField] = useState();
  const translatedMessages = messages(_t);
  const yesNoRadio = YES_NO_RADIO(_t);
  const disabledEnabledRadio = DISABLED_ENABLED_RADIO(_t);
  const statusesRadio = STATUSES_RADIO(_t);
  const languageRadio = LANGUAGES_RADIO(_t);
  const remain_password_link =
    window.location.origin +
    APP_ROUTES.user.reset_password +
    `?email=${user.email}&key=${remain_password_key}`;

  const handleSubmit = (values) => {
    submit({
      data: values,
      messages: translatedMessages,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setEditedField(null);
    }
  }, [isSuccess]);
  const { data: roles, isLoading: rolesIsLoading } = useGetRolesList();

  return (
    <>
      <Card>
        <List size="small">
          <FullWidthRow
            title={_t("traffic_type")}
            value={
              <CollapsableRadio
                user={user}
                value={user_settings.traffic_type}
                name={"settings.traffic_type"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={traffic_types}
                textValue={traffic_types[user_settings.traffic_type]}
              />
            }
          />
          <FullWidthRow
            title={_t("user_type")}
            value={
              <CollapsableRadio
                user={user}
                value={user.user_type_id}
                name="user_type_id"
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={user_types}
                textValue={user_types[user.user_type_id]}
              />
            }
          />
          <FullWidthRow
            title={_t("Id")}
            value={<SimpleText>{user.id}</SimpleText>}
          />
          <FullWidthRow
            title={_t("Login")}
            value={<SimpleText>{user.login}</SimpleText>}
          />

          <FullWidthRow
            title={_t("Email")}
            value={
              <CollapsableInput
                user={user}
                value={user.email}
                name={"email"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("email_verified")}
            value={
              <CollapsableRadio
                user={user}
                value={user.email_verified}
                name={"email_verified"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user.email_verified]}
              />
            }
          />
          <FullWidthRow
            title={_t("first_name")}
            value={
              <CollapsableInput
                user={user}
                value={user.first_name}
                name={"first_name"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("last_name")}
            value={
              <CollapsableInput
                user={user}
                value={user.last_name}
                name={"last_name"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("nickname")}
            value={
              <CollapsableInput
                user={user}
                value={user_settings.custom_nick}
                name={"settings.custom_nick"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("cash_type")}
            value={
              <CollapsableRadio
                user={user}
                value={user.cash_type}
                name={"cash_type"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={cash_types}
                textValue={cash_types[user.cash_type]}
              />
            }
          />
          <FullWidthRow
            title={_t("wallet")}
            value={
              <CollapsableTextArea
                user={user}
                value={user.wallet}
                name={"wallet"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("activated")}
            value={
              <CollapsableRadio
                user={user}
                value={user.activated}
                name={"activated"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user.activated]}
              />
            }
          />
          <FullWidthRow
            title={_t("status")}
            value={
              <CollapsableRadio
                user={user}
                value={user.status}
                name={"status"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={statusesRadio}
                textValue={statusesRadio[user.status]}
              />
            }
          />
          <FullWidthRow
            title={_t("role")}
            value={
              <CollapsableRadio
                user={user}
                value={user.role}
                name={"role"}
                isLoading={isLoading && rolesIsLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={roles?.data ?? []}
                textValue={roles?.data[user.role]}
              />
            }
          />
          <FullWidthRow
            title={_t("language")}
            value={
              <CollapsableRadio
                user={user}
                value={user.language}
                name={"language"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={languageRadio}
                textValue={languageRadio[user.language]}
              />
            }
          />

          <FullWidthRow
            title={_t("last_login")}
            value={<SimpleText>{user.last_login}</SimpleText>}
          />
          <FullWidthRow
            title={_t("registration_date")}
            value={<SimpleText>{user.created_at}</SimpleText>}
          />
          <FullWidthRow
            title={_t("profile_update_date")}
            value={<SimpleText>{user.updated_at}</SimpleText>}
          />
          <FullWidthRow
            title={_t("projects")}
            value={projects.map((project, index) => {
              return (
                <div key={"project_" + index}>
                  <SimpleText>{project.name}</SimpleText>
                </div>
              );
            })}
          />
          <FullWidthRow
            title={_t("manager")}
            value={
              <CollapsableRadio
                user={user}
                value={user.manager_id}
                name={"manager_id"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={managers}
                textValue={managers[user.manager_id ?? 0]}
              />
            }
          />
          <FullWidthRow
            title={_t("company_name")}
            value={
              <CollapsableInput
                user={user}
                value={user.company_name}
                name={"company_name"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("company_address_1")}
            value={
              <CollapsableInput
                user={user}
                value={user.company_address_1}
                name={"company_address_1"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("company_address_2")}
            value={
              <CollapsableInput
                user={user}
                value={user.company_address_2}
                name={"company_address_2"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("zip_code")}
            value={
              <CollapsableInput
                user={user}
                value={user.zip_code}
                name={"zip_code"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("country")}
            value={
              <CollapsableInput
                user={user}
                value={user.country}
                name={"country"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("contacts")}
            value={
              <CollapsableInput
                user={user}
                value={user.contacts}
                name={"contacts"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("tax_number")}
            value={
              <CollapsableInput
                user={user}
                value={user.tax_number}
                name={"tax_number"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("web_url")}
            value={
              <CollapsableInput
                user={user}
                value={user.web_url}
                name={"web_url"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
        </List>
      </Card>
      <Divider />
      <Card title={_t("settings")}>
        <List size="small">
          <FullWidthRow
            title={_t("allow_logins")}
            value={
              <CollapsableRadio
                user={user}
                value={user_settings.show_gamers_login}
                name={"settings.show_gamers_login"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user_settings.show_gamers_login]}
              />
            }
          />
          <FullWidthRow
            title={_t("enable_postbacks")}
            value={
              <CollapsableRadio
                user={user}
                value={user_settings.allow_postbacks}
                name={"settings.allow_postbacks"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user_settings.allow_postbacks]}
              />
            }
          />
          <FullWidthRow
            title={_t("hide_finance")}
            value={
              <CollapsableRadio
                user={user}
                value={user_settings.hide_finance}
                name={"settings.hide_finance"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user_settings.hide_finance]}
              />
            }
          />
          <FullWidthRow
            title={_t("invited_with")}
            value={
              <>
                <CollapsableInput
                  user={user}
                  value={user.invited_with}
                  name={"invited_with"}
                  isLoading={isLoading}
                  onSubmit={handleSubmit}
                  selected={editedField}
                  setEditable={setEditedField}
                />
                {user.invited_with_promo && (
                  <Link
                    to={generatePath(APP_ROUTES.manage.user_profile, {
                      partnerId: user.invited_with_promo.partner_id,
                    })}
                  >
                    ( {user.invited_with_promo.partner_login} )
                  </Link>
                )}
              </>
            }
          />
          <FullWidthRow
            title={_t("phone")}
            value={
              <CollapsableInput
                user={user}
                value={user.phone}
                name={"phone"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("skype")}
            value={
              <CollapsableInput
                user={user}
                value={user.skype}
                name={"skype"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("Subscription Language")}
            value={
              <CollapsableRadio
                user={user}
                value={user.subscription_language}
                name={"subscription_language"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={languageRadio}
                textValue={languageRadio[user.subscription_language]}
              />
            }
          />
          <FullWidthRow
            title={_t("is_subscribed")}
            value={
              <CollapsableRadio
                user={user}
                value={user.is_subscribed}
                name={"is_subscribed"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user.is_subscribed]}
              />
            }
          />
          <FullWidthRow
            title={_t("Http_Referer")}
            value={<SimpleText>{user.http_referer}</SimpleText>}
          />
          <FullWidthRow
            title={_t("show_in_tops")}
            value={
              <CollapsableRadio
                user={user}
                value={user.show_in_tops}
                name={"show_in_tops"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user.show_in_tops]}
              />
            }
          />
          <FullWidthRow
            title={_t("allow_csv")}
            value={
              <CollapsableRadio
                user={user}
                value={user.csv_settings}
                name={"csv_settings"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={yesNoRadio}
                textValue={yesNoRadio[user.csv_settings]}
              />
            }
          />
          <FullWidthRow
            title={_t("password_reset_link")}
            value={
              <CopyToClipboard value={remain_password_link}>
                <span style={{ wordBreak: "break-all" }}>
                  {remain_password_link}
                </span>
              </CopyToClipboard>
            }
          />
          <FullWidthRow
            title={_t("manager")}
            value={<SimpleText>{_t("not_specified")}</SimpleText>}
          />
          <FullWidthRow
            title={_t("revshare_affects_cpa")}
            value={
              <CollapsableRadio
                user={user}
                value={user_settings.revshare_affects_cpa}
                name={"settings.revshare_affects_cpa"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={disabledEnabledRadio}
                textValue={
                  disabledEnabledRadio[user_settings.revshare_affects_cpa]
                }
              />
            }
          />
          <FullWidthRow
            title={_t("Negative Carryover")}
            value={
              <CollapsableRadio
                user={user}
                value={user_settings.negative_carryover}
                name={"settings.negative_carryover"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
                radioItems={disabledEnabledRadio}
                textValue={
                  disabledEnabledRadio[user_settings.negative_carryover]
                }
              />
            }
          />
          <FullWidthRow
            title={_t("notes")}
            value={
              <CollapsableTextArea
                user={user}
                value={user.notes}
                name={"notes"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("resources")}
            value={
              <CollapsableTextArea
                user={user}
                value={user.resources}
                name={"resources"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
        </List>
      </Card>
      <Divider />
      <Card title={_t("general_postback")}>
        <List size="small">
          <FullWidthRow
            title={_t("register_url")}
            value={
              <CollapsableInput
                user={user}
                value={user_settings.postback_general_register_url}
                name={"settings.postback_general_register_url"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("deposit_url")}
            value={
              <CollapsableInput
                user={user}
                value={user_settings.postback_general_deposit_url}
                name={"settings.postback_general_deposit_url"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("first_deposit_url")}
            value={
              <CollapsableInput
                user={user}
                value={user_settings.postback_general_first_deposit_url}
                name={"settings.postback_general_first_deposit_url"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
          <FullWidthRow
            title={_t("install_url")}
            value={
              <CollapsableInput
                user={user}
                value={user_settings.postback_general_install_url}
                name={"settings.postback_general_install_url"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                selected={editedField}
                setEditable={setEditedField}
              />
            }
          />
        </List>
      </Card>
    </>
  );
};
