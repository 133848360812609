import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "../../modules/User/reducers";
import filters from "../../modules/Dashboard/reducer";
import costs from "../../modules/Costs/reducer";
import promo from "../../modules/Promo/reducer";
import locale from "../../modules/Locales/reducer";
import reportGamersFTD from "../../pages/inner/Reports/pages/GamersFTDReport/redux/reducer";
import reportGamers from "../../pages/inner/Reports/pages/GamersReport/redux/reducer";
import reportAffiliates from "../../pages/inner/Reports/pages/AffiliatesReport/redux/reducer";
import reportGeoAffiliates from "../../pages/inner/Reports/pages/AffiliatesGeoReport/redux/reducer";
import reportGeoAffiliatesDetails from "../../pages/inner/Reports/pages/AffiliatesGeoReportDetail/redux/reducer";
import reportAffiliatesDifference from "../../pages/inner/Reports/pages/AffiliateDifferenceReport/redux/reducer";
import reportGeo from "../../pages/inner/Reports/pages/GeoReport/redux/reducer";
import reportTotals from "../../pages/inner/Reports/pages/TotalsReport/redux/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    locale,
    user,
    filters,
    costs,
    promo,
    reportGamersFTD,
    reportGamers,
    reportAffiliates,
    reportAffiliatesDifference,
    reportGeo,
    reportGeoAffiliates,
    reportGeoAffiliatesDetails,
    reportTotals,
  });

export default createRootReducer;
