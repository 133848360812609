import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { isUserAllowed } from "../../../../../../../../common/helpers";
import { permissions } from "../../../../../../../../system/constants/roles";
import { ReactComponent as ArrowDownIcon } from "../../../../../../../../assets/images/arrow-down-icon.svg";

const NavItem = (props: any) => {
  const [isOpened, setIsOpened] = useState(false);
  const NavTag = props.children && !props.isCollapsed ? "span" : NavLink;
  const Icon = props.icon;

  useEffect(() => {
    if (props.isCollapsed) {
      setIsOpened(false);
    }
  }, [props.isCollapsed]);

  const handleSubMenuOpening = () => {
    if (props.children && !props.isCollapsed) {
      setIsOpened(!isOpened);
    }
  };
  return (
    <>
      {isUserAllowed(props.permissions || permissions.user_permission.all) && (
        <li>
          <NavTag
            to={props.path || (props.children && props.children[0].path) || "/"}
            className={classNames(
              { active: isOpened, hasChildren: props.children },
              "sidebar-nav-item"
            )}
            onClick={handleSubMenuOpening}
          >
            {Icon && (
              <span className="sidebar-nav-item__icon">
                <Icon />
              </span>
            )}
            <span className="sidebar-nav-item__text">
              {props.label}
              {props.children && (
                <span className="sidebar-nav-item__arrow">
                  <ArrowDownIcon />
                </span>
              )}
            </span>
          </NavTag>

          {props.children && (
            <ul className="sidebar-nav-drop">
              {props.children.map((item) => (
                <li key={item.label}>
                  <NavLink to={item.path}>{item.label}</NavLink>
                </li>
              ))}
            </ul>
          )}
        </li>
      )}
    </>
  );
};

export default NavItem;
