import React from "react";
import { _t } from "./InjectIntlContext";
import Icon from "../../../assets/images/file-minus-icon.svg";
export function NoData(props) {
  return (
    <div className="empty-box" {...props}>
      <div className="empty-box__icon">
        <img src={Icon} alt="" />
      </div>
      <p className="empty-box__title">{_t("no_data")}</p>
    </div>
  );
}
