import React from "react";
import { Card } from "antd";
import { Post } from "../constants";
const { Meta } = Card;

type PostViewProps = {
  post: Post;
};

export function PostView({ post }: PostViewProps) {
  return (
    <Card
      key={post?.id}
      className={"news-list__post"}
      cover={
        <img
          className={"news-list__post-img"}
          alt="example"
          src={`${post?.host}${post?.options_parsed?.image}`}
        />
      }
    >
      <Meta
        className={"news-list__post-content"}
        title={
          <>
            <div className={"news-list__post-date"}>{post?.post_date}</div>
            <div>{post?.caption}</div>
          </>
        }
        description={
          <div
            className={"news-list__post-content-text"}
            dangerouslySetInnerHTML={{ __html: post?.text }}
          ></div>
        }
      />
    </Card>
  );
}
