import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getItem,
  getLocale,
} from "../../../../system/helpers/localstorageHelper";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { ReactComponent as Logo } from "../../../../images/icons/sidebar/logo.svg";
import {
  SidebarControls,
  SidebarNavigation,
  SidebarToggleButton,
} from "./components";
import classNames from "classnames";
import LandingLanguages from "../../../Landing/components/LandingLanguages";
import config from "../../../../system/config";

const Sidebar = () => {
  let location = useLocation();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);

  useEffect(() => {
    setIsMenuOpened(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  return (
    <div className="sidebar">
      <Link
        to={
          getItem("role") === "admin"
            ? APP_ROUTES.dashboard.admin
            : APP_ROUTES.dashboard.general
        }
        className="sidebar-logo"
      >
        <img src={`${config.imagesDirectory}/logo-dark.svg`} alt="" />
      </Link>
      <SidebarNavigation />
      <LandingLanguages />
      <SidebarControls />
      <button
        className={classNames("sidebar-burger-btn", {
          active: isMenuOpened,
        })}
        onClick={() => setIsMenuOpened(!isMenuOpened)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      {isMenuOpened && (
        <div className="mobile-inner-menu">
          <div className="mobile-inner-menu__top">
            <LandingLanguages />
            <SidebarControls />
          </div>
          <SidebarNavigation />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
