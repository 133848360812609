import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, generatePath, useLocation } from "react-router-dom";
import { ReactComponent as SignIn } from "../../../assets/images/sign-in-icon.svg";
import { ReactComponent as SignUp } from "../../../assets/images/sign-up-icon.svg";
import imageArrow from "../../../assets/images/arrow-down-icon.svg";
import MobileMenuImg from "../../../images/landings/mobile-menu.png";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import classNames from "classnames";
import {
  getItem,
  isAuthorized,
} from "../../../system/helpers/localstorageHelper";
import config from "../../../system/config";
import { LanguageSelect } from "../../../common/components";

const Header = () => {
  const body = useRef(document.getElementsByTagName("body")[0]);
  const [modalMenu, toggleMenu] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const anchorElement =
      location.hash && document.querySelector(location.hash);
    if (anchorElement) {
      document.querySelector(location.hash).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
    toggleMenu(false);
  }, [location.pathname, location.hash]);
  useEffect(() => {
    const bodyElement = body.current;
    modalMenu
      ? (bodyElement.style.overflow = "hidden")
      : (bodyElement.style.overflow = "auto");
  }, [modalMenu]);

  const navigationItems = [
    {
      path: APP_ROUTES.commissions.commission,
      label: _t("Commissions"),
      anchorLink: true,
    },
    {
      label: _t("FAQ"),
      path: APP_ROUTES.faq.index,
      children: [
        {
          path: generatePath(APP_ROUTES.faq.index, {
            category: "general",
          }),
          label: _t("general"),
        },
        {
          path: generatePath(APP_ROUTES.faq.index, {
            category: "finance",
          }),
          label: _t("financial"),
        },
        {
          path: generatePath(APP_ROUTES.faq.index, {
            category: "technical",
          }),
          label: _t("technical"),
        },
      ],
    },
    {
      path: APP_ROUTES.contacts,
      anchorLink: true,
      label: _t("Contacts"),
    },
    {
      path:
        getItem("role") === "admin"
          ? APP_ROUTES.dashboard.admin
          : APP_ROUTES.dashboard.general,
      label: _t("Dashboard"),
      isHidden: !isAuthorized(),
    },
  ];

  const HeaderNav = () => (
    <ul className="pages-nav pages-nav--header">
      {navigationItems.map((item) => {
        if (item.isHidden) return null;
        return (
          <li key={item.path}>
            {item.children ? (
              <div className="pages-nav-drop">
                <span className="pages-nav-drop__link">
                  {item.label}
                  <span className="arrow-down">
                    <img src={imageArrow} alt="" />
                  </span>
                </span>
                <div className="pages-nav-drop__drop-content">
                  <ul>
                    {item.children.map((dropItem) => (
                      <li key={dropItem.path}>
                        <NavLink
                          to={dropItem.path}
                          onClick={() => toggleMenu(false)}
                        >
                          {dropItem.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <>
                {item.anchorLink ? (
                  <a href={item.path}>{item.label}</a>
                ) : (
                  <NavLink to={item.path}>{item.label}</NavLink>
                )}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );

  return (
    <header className="header">
      <div className="container container--big">
        <div className="header__wrap">
          <NavLink to="/" className="header-logo">
            <img src={`${config.imagesDirectory}/logo.svg`} alt="Le Bull"/>
          </NavLink>
          <HeaderNav/>
          <LanguageSelect/>
          <button className="burger-menu" onClick={() => toggleMenu(true)}>
            <img src={MobileMenuImg} alt="burger" />
          </button>
          {!isAuthorized() && (
            <div className="header__buttons">
              <Link to={APP_ROUTES.login.main} className="btn btn--border">
                <SignIn />
                {_t("sign_in")}
              </Link>
              <Link to={APP_ROUTES.registration} className="btn">
                <SignUp />
                {_t("sign_up")}
              </Link>
            </div>
          )}
          <div className={classNames("menu-modal", modalMenu && "opened")}>
            <div className="menu-modal__content">
              <button
                type="button"
                className="close-modal-button"
                onClick={() => toggleMenu(false)}
              >
                x
              </button>
              <HeaderNav />
              <LanguageSelect />
              {!isAuthorized() && (
                <div className="header__buttons">
                  <Link to={APP_ROUTES.registration} className="btn">
                    {_t("sign_up")}
                  </Link>
                  <Link to={APP_ROUTES.login.main} className="btn btn--border">
                    {_t("login")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
