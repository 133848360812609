import { useMutation, useQueryCache } from "react-query";
import { API_KEY_GET_FAQ, API_KEY_GET_FAQ_LIST } from "../constants";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { notify } from "../../../../../system/utils/notification";
import { postFaq } from "../api";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { history } from "../../../../../system/store";

export const usePostFaq = (id?) => {
  const successMessage = _t("successfully_sent");
  const queryCache = useQueryCache();

  return useMutation(postFaq, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries(API_KEY_GET_FAQ, id);
      queryCache.invalidateQueries(API_KEY_GET_FAQ_LIST);
      notify("success", successMessage);
      history.push(APP_ROUTES.content.faq.list);
    },
  });
};
