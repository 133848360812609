import React, { useState } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";
import classNames from "classnames";

const slides = (_t: any) => [
  {
    title: _t("how_1"),
    text: _t("how_1_text"),
    icon: `${config.imagesDirectory}/how-it-work-1.jpg`,
  },
  {
    title: _t("how_2"),
    text: _t("how_2_text"),
    icon: `${config.imagesDirectory}/how-it-work-2.jpg`,
  },
  {
    title: _t("how_3"),
    text: _t("how_3_text"),
    icon: `${config.imagesDirectory}/how-it-work-3.jpg`,
  },
  {
    title: _t("how_4"),
    text: _t("how_4_text"),
    icon: `${config.imagesDirectory}/how-it-work-4.jpg`,
  },
];

const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState(0);
  const howItWorksCards = slides(_t);
  return (
    <section className="how-it-works custom-section">
      <div className="container">
        <h2 className="custom-section__title">{_t("how_it_works")}</h2>
        <div className="how-it-works-content">
          <div className="how-it-works-content__images">
            {howItWorksCards.map((item, idx) => (
              <img
                src={item.icon}
                alt=""
                key={idx}
                className={classNames({
                  active: idx === activeTab,
                })}
              />
            ))}
          </div>
          <ul className="how-it-work-nav">
            {howItWorksCards.map((item, idx) => (
              <li key={idx}>
                <button
                  className={classNames({
                    active: idx === activeTab,
                  })}
                  onClick={() => setActiveTab(idx)}
                >
                  {item.title}
                  <span>{idx + 1}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
