import React from "react";

type ProjectInfoProps = {
  img: string;
  href: string;
  type: string;
};

const ProjectInfo: React.FC<ProjectInfoProps> = ({ img, href, type }) => {
  return (
    <div className={"project-info"}>
      <div className={"project-info__type"}>{type}</div>
      <img className={"project-info__img"} src={img} alt={"Project logo"} />
    </div>
  );
};

export default ProjectInfo;
