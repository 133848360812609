import React, { useState } from "react";
import { FaqResponse } from "../../api";
import { QA } from "../QA/QA";
import { Spin } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";

type FaqsProps = {
  data: FaqResponse;
  loading: boolean;
};

export function Faqs({ data, loading }: FaqsProps) {
  const [toggler, setToggle] = useState();
  return (
    <>
      <Spin spinning={loading}>
        <div className="faq-list">
          {data?.faq?.map((item) => (
            <QA
              faq={item}
              key={item.id}
              isOpen={item.id === toggler}
              setToggle={setToggle}
            />
          ))}
        </div>
      </Spin>
    </>
  );
}
