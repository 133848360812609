import React, { useState } from "react";
import { useGetAffiliatePrograms } from "../../hooks";
import { Button, Space, Spin, Table, Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  AffiliateProgramControls,
  AffiliateProgramExpandInfo,
  AffiliateProgramStatusSelect,
} from "./index";
import { getItem } from "../../../../../system/helpers/localstorageHelper";
import { handleStatusType } from "../../../../../common/helpers";

const AffiliatePrograms = ({ id, name }) => {
  const [status, setStatus] = useState(
    getItem(`${id}-program-status`) || "public"
  );
  const { data, isLoading } = useGetAffiliatePrograms({
    project_id: id,
    status: status,
  });
  const dataWithKeys = data?.programs?.map((item) => ({
    ...item,
    key: item.id,
  }));
  const [expandOptions, setExpandOptions] = useState([]);
  const [expandUsers, setExpandUsers] = useState([]);

  const handleExpandOpen = (type, key) => {
    switch (type) {
      case "options": {
        if (expandOptions.includes(key)) {
          setExpandOptions(expandOptions.filter((item) => item !== key));
          break;
        }
        setExpandOptions([...expandOptions, key]);
        break;
      }
      case "users": {
        if (expandUsers.includes(key)) {
          setExpandUsers(expandUsers.filter((item) => item !== key));
          break;
        }
        setExpandUsers([...expandUsers, key]);
        break;
      }
      default:
        return null;
    }
  };

  const programTableData = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: _t("Name"), dataIndex: "name", key: "name" },
    { title: _t("Type"), dataIndex: "type", key: "type" },
    {
      title: _t("Status"),
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => (
        <AffiliateProgramStatusSelect
          defaultValue={dataWithKeys[index].status}
          id={dataWithKeys[index].id}
          projectId={id}
          projectStatus={status}
        />
      ),
    },
    { title: _t("description"), dataIndex: "description", key: "description" },
    {
      title: _t("actions"),
      dataIndex: "actions",
      render: (text, record, index) => (
        <AffiliateProgramControls
          title={name}
          rowKey={dataWithKeys[index].id}
          status={dataWithKeys[index].status}
          handleExpandOpen={handleExpandOpen}
        />
      ),
    },
  ];

  return (
    <div className="brand-affiliate-programs">
      <Space style={{ margin: "10px 0" }}>
        <Typography.Title level={5} style={{ marginBottom: "0" }}>
          {_t("table_title_affiliate_programs")}
        </Typography.Title>
        <Button
          shape="round"
          onClick={() =>
            handleStatusType(status, setStatus, `${id}-program-status`)
          }
          icon={status === "all" ? <UpOutlined /> : <DownOutlined />}
        >
          {status === "all" ? _t("show_public") : _t("show_all")}
        </Button>
      </Space>
      <Spin spinning={isLoading} style={{ display: "block" }}>
        {data && (
          <Table
            className="affiliate-programs-table"
            columns={programTableData}
            dataSource={dataWithKeys}
            pagination={false}
            expandIconColumnIndex={-1}
            expandable={{
              expandedRowRender: (record) => (
                <AffiliateProgramExpandInfo
                  id={record.id}
                  status={record.status}
                  options={record.options}
                  specialUsers={record.special_users}
                  optionsDrop={expandOptions}
                  specialUsersDrop={expandUsers}
                />
              ),
              defaultExpandAllRows: true,
            }}
          />
        )}
      </Spin>
    </div>
  );
};

export default AffiliatePrograms;
