import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UserProfileIcon } from "../../../../../../assets/images/user-profile.svg";
import { ReactComponent as LogoutIcon } from "../../../../../../assets/images/logout.svg";
import {
  getItem,
  removeItem,
} from "../../../../../../system/helpers/localstorageHelper";
import {
  AdminMails,
  UserMails,
} from "../../../../../../pages/inner/components";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import { logoutAction } from "../../../../../Auth/actions";

const SidebarControls = () => {
  const isAdmin = getItem("role") === "admin";
  const userInfo = useSelector((state) => state?.user?.basicInfo);
  const dispatch = useDispatch();

  const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(logoutAction());
    removeItem("isShowNotifications");
  };
  return (
    <div className="sidebar-controls">
      {isAdmin ? <AdminMails /> : <UserMails />}

      <div className="user-profile-preview">
        <Link to={APP_ROUTES.user.main} className="sidebar-nav-item">
          {userInfo.login ? (
            <>
              <span className="sidebar-nav-item__icon">
                <UserProfileIcon />
              </span>
              <span className="sidebar-nav-item__text">
                {userInfo.login}
                <span className="user-profile-preview__id">#{userInfo.id}</span>
              </span>
            </>
          ) : (
            "Loading..."
          )}
        </Link>
        <button className="logout-btn" onClick={logout}>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
};

export default SidebarControls;
