import React from "react";
import { Form, Input, Checkbox, Spin } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../system/config";
import { getItem, getLocale } from "../../../system/helpers/localstorageHelper";
import { INVITATION_CODE } from "../../Landing/constants";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

export function RegistrationForm({ onSubmit, isLoading }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const locale = getLocale();
  const recaptchaRef = React.createRef();
  const invitationCode = getItem(INVITATION_CODE);
  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Form
      form={form}
      name="register"
      onFinish={onSubmit}
      initialValues={{
        invitation_code: invitationCode,
      }}
      scrollToFirstError
      className="auth-form"
      {...formLayout}
      layout="vertical"
    >
      {invitationCode && (
        <Form.Item name="invitation_code" hidden={true}>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="login"
        rules={[
          {
            required: true,
            message: _t("enter_login_message"),
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={_t("Login")} />
      </Form.Item>
      <Form.Item
        name="first_name"
        rules={[
          {
            required: true,
            message: _t("enter_first_name"),
          },
        ]}
      >
        <Input placeholder={_t("first_name")} />
      </Form.Item>
      <Form.Item
        name="last_name"
        rules={[
          {
            required: true,
            message: _t("enter_last_name"),
          },
        ]}
      >
        <Input placeholder={_t("last_name")} />
      </Form.Item>
      <Form.Item
        name="company_name"
        rules={[
          {
            required: true,
            message: _t("enter_company_name"),
          },
        ]}
      >
        <Input placeholder={_t("company_name")} />
      </Form.Item>
      <Form.Item
        name="company_address_1"
        rules={[
          {
            required: true,
            message: _t("enter_company_address_1"),
          },
        ]}
      >
        <Input placeholder={_t("company_address_1")} />
      </Form.Item>
      <Form.Item
        name="company_address_2"
      >
        <Input placeholder={_t("company_address_2")} />
      </Form.Item>
      <Form.Item
        name="zip_code"
        rules={[
          {
            required: true,
            message: _t("enter_zip_code"),
          },
        ]}
      >
        <Input placeholder={_t("zip_code")} />
      </Form.Item>
      <Form.Item
        name="country"
        rules={[
          {
            required: true,
            message: _t("enter_country"),
          },
        ]}
      >
        <Input placeholder={_t("country")} />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
      >
        <Input placeholder={_t("E-mail")} />
      </Form.Item>


      <Form.Item
        name="phone"
        rules={[{ required: true, message: _t("enter_cell_phone") }]}
      >
        <Input style={{ width: "100%" }} placeholder={_t("cell_phone")} />
      </Form.Item>

      <Form.Item
        name="contacts"
        rules={[{ required: true, message: _t("Please input your contacts!") }]}
      >
        <Input style={{ width: "100%" }} placeholder={'Skype'} />
      </Form.Item>

      <Form.Item
        name="tax_number"
        rules={[{ required: true, message: _t("enter_tax_number") }]}
      >
        <Input style={{ width: "100%" }} placeholder={_t("tax_number")} />
      </Form.Item>
      <Form.Item
        name="web_url"
        rules={[{ required: true, message: _t("enter_web_url") }]}
      >
        <Input style={{ width: "100%" }} placeholder={_t("web_url")} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: _t("input_password_message"),
          },
          {
            min: 6,
            message: _t("min_password_message"),
          },
        ]}
      >
        <Input.Password placeholder={_t("Password")} />
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: _t("confirm_password_message"),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(_t("error_confirm_password_message"));
            },
          }),
        ]}
      >
        <Input.Password placeholder={_t("confirm_password")} />
      </Form.Item>
      <Form.Item
        name="terms"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(_t("accept_terms")),
          },
        ]}
        wrapperCol={{ span: 24 }}
        className="auth-check"
      >
        <Checkbox>
          {_t("agree_with")}{" "}
          <a href={APP_ROUTES.terms}> {_t("terms_conditions")}</a>
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="g-recaptcha-response"
        rules={[
          {
            required: true,
            message: _t("recaptcha_required"),
          },
        ]}
        className="recaptcha"
        wrapperCol={{ span: 24 }}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.google_recaptcha_sitekey}
          hl={locale}
        />
      </Form.Item>
      <div className="auth-form__footer">
        <button
          className="btn btn--border"
          type="button"
          onClick={() => history.goBack()}
        >
          {_t("Back")}
        </button>
        <button className="btn" type="submit" disabled={isLoading}>
          {_t("register")}
        </button>
        <NavLink to={APP_ROUTES.login.main}>
          {_t("have_account")} {_t("sign_in")}!
        </NavLink>
      </div>
    </Form>
  );
}
