import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";

const NavCard = (props: any) => {
  const buttonClassNames = `btn btn--${props.buttonProps?.modifier || "dark"}`;
  return (
    <div className={props.className}>
      <div className="nav-card__content">
        <h2 className="nav-card__title">{_t(props.title)}</h2>
        <h2 className="nav-card__text">{_t(props.text)}</h2>
        {props.outerLink ? (
          <a
            href={props.linkPath}
            target="_blank"
            rel="noreferrer noopener"
            className={buttonClassNames}
          >
            {_t("read_more")}
          </a>
        ) : (
          <Link className={buttonClassNames} to={props.linkPath}>
            {_t("read_more")}
          </Link>
        )}
      </div>
      {props.children}
    </div>
  );
};

export default NavCard;
