import React from "react";
import { Form, Input } from "antd";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useSendContacts } from "../../Contacts/apiHooks";
import { _t } from "../../Common/components/InjectIntlContext";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../system/config";

const ContactsSection = () => {
  const [SendContacts, { isLoading }] = useSendContacts();
  const recaptchaRef = React.createRef();
  const locale = getLocale();
  return (
    <div className="custom-section" id="contacts">
      <div className="container">
        <div className="contact-content">
          <Form
            action={APP_ROUTES.contacts}
            name="contacts_form"
            className="contact-form"
            method="post"
            onFinish={SendContacts}
          >
            <h2 className="custom-section__title">{_t("get_in_touch")}</h2>
            <p className="contact-form__text">
              {_t("contacts_welcome_message")}
            </p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: _t("input_name"),
                },
              ]}
            >
              <Input placeholder={_t("Name")} />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: _t("input_email"),
                },
                {
                  type: "email",
                  message: _t("not_valid_email_message"),
                },
              ]}
            >
              <Input placeholder={_t("Email")} />
            </Form.Item>

            <Form.Item
              name="text"
              className="textarea-wrap"
              rules={[
                {
                  required: true,
                  message: _t("Please input details"),
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder={_t("your_message")} />
            </Form.Item>
            <Form.Item
              name="g-recaptcha-response"
              rules={[
                {
                  required: true,
                  message: _t("recaptcha_required"),
                },
              ]}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.google_recaptcha_sitekey}
                className={"recaptcha"}
                hl={locale}
              />
            </Form.Item>
            <button className="btn btn--red" type="submit" disabled={isLoading}>
              {_t("Send")}
            </button>
          </Form>
          <div className="contact-content__image">
            <img src={`${config.imagesDirectory}/bull_3.png`} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsSection;
