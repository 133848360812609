import React, { useState } from "react";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { useGetTopStats } from "./hooks";
import { Period } from "./helpers";
import { Divider, Select, Spin } from "antd";
import { StatsCard, Totals } from "./components";
import { FiltersWidget } from "../../../modules/Users/components/FiltersWidget";
import { UsersListWidget } from "../../../modules/Users/components/UsersListWidget";
import { useUsersList } from "../../../modules/Users/apiHooks";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { PageTemplate } from "../../../modules/Dashboard/pages/PageTemplate";

const AdminDashboard = () => {
  const [dataPeriod, setDataPeriod] = useState<Period["value"]>("15 days");
  const [filters, setFilters] = useState({
    search: "",
  });
  const [page, setPage] = useState(1);
  const pageSize = 5;

  const { data: usersData, isLoading: isUsersDataLoading } = useUsersList({
    ...filters,
    page,
    page_size: pageSize,
  });

  const userTableData: any = {
    data: {
      ...usersData?.data,
      total: 5,
    },
  };
  const periodOptions = [
    {
      label: `15 ${_t("days")}`,
      value: "15 days",
    },
    {
      label: `30 ${_t("days")}`,
      value: "30 days",
    },
    {
      label: `90 ${_t("days")}`,
      value: "90 days",
    },
  ];
  const { data, isLoading } = useGetTopStats();
  const adminStats = ["hosts", "regs", "first_deposits", "partner_income"].map(
    (item) => ({
      title: item,
      data:
        data?.list &&
        data.list[dataPeriod] !== undefined &&
        data.list[dataPeriod][item] !== undefined
          ? data.list[dataPeriod][item]
          : [],
    })
  );
  return (
    <PageTemplate>
      <div className="admin-stats-card">
        <div className="admin-stats-card__top">
          <p>{_t("admin_dashboard_top_users")}</p>
          <Select
            options={periodOptions}
            defaultValue={dataPeriod}
            onChange={setDataPeriod}
          />
        </div>
        {data ? (
          <div className="admin-stats-grid">
            {adminStats.map((item) => (
              <div
                className="admin-stats-grid__item"
                key={`${item.title}${dataPeriod}`}
              >
                <StatsCard {...item} />
              </div>
            ))}
          </div>
        ) : (
          <Spin spinning={isLoading} />
        )}
      </div>
      <Totals />
      <Link
        to={APP_ROUTES.manage.users}
        style={{
          display: "inline-block",
          fontSize: "16px",
          fontFamily: "Baloo",
          marginBottom: "15px",
        }}
      >
        {_t("manage_users")}
      </Link>
      {isUsersDataLoading ? (
        <Spin spinning={isUsersDataLoading} />
      ) : (
        <>
          <FiltersWidget
            onSubmit={setFilters}
            initialValues={filters}
            isSubmitting={isUsersDataLoading}
          />

          <Divider />
          <UsersListWidget
            isLoading={isUsersDataLoading}
            data={userTableData}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
          />
        </>
      )}
    </PageTemplate>
  );
};

export default AdminDashboard;
