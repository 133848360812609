import React from "react";
import { ReactComponent as DashboardIcon } from "../../../../../../assets/images/sidebar-menu/dashboard.svg";
import { ReactComponent as PromoIcon } from "../../../../../../assets/images/sidebar-menu/promotion.svg";
import { ReactComponent as TrackersIcon } from "../../../../../../assets/images/sidebar-menu/tracker.svg";
import { ReactComponent as PaymentIcon } from "../../../../../../assets/images/sidebar-menu/payments.svg";
import { ReactComponent as AffiliatesIcon } from "../../../../../../assets/images/sidebar-menu/affiliate.svg";
import { ReactComponent as CostsIcon } from "../../../../../../assets/images/sidebar-menu/costs.svg";
import { ReactComponent as ManageIcon } from "../../../../../../assets/images/sidebar-menu/manage.svg";
import { ReactComponent as ReportIcon } from "../../../../../../assets/images/sidebar-menu/reports.svg";
import { ReactComponent as ContentIcon } from "../../../../../../assets/images/sidebar-menu/content.svg";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import { permissions } from "../../../../../../system/constants/roles";
import { _t } from "../../../../../Common/components/InjectIntlContext";
import { NavItem } from "./components";

const NAVIGATION_SIDEBAR = (_t) => [
  {
    label: _t("Dashboard"),
    // icon: DashboardIcon,
    path: APP_ROUTES.dashboard.general,
    permissions: permissions.user_permission.users,
  },
  {
    label: _t("admin_dashboard"),
    // icon: DashboardIcon,
    path: APP_ROUTES.dashboard.admin,
    permissions: permissions.user_permission.admin,
  },
  {
    label: _t("Promo Materials"),
    // icon: PromoIcon,
    path: APP_ROUTES.promo.create,
  },
  // {
  //   label: _t("Trackers"),
  //   // icon: TrackersIcon,
  //   path: APP_ROUTES.trackers,
  // },
  {
    label: _t("Payments"),
    // icon: PaymentIcon,
    path: APP_ROUTES.payments,
  },
  {
    label: _t("Affiliates"),
    // icon: AffiliatesIcon,
    path: APP_ROUTES.affiliates,
  },
  {
    label: _t("postback_debug"),
    // icon: CostsIcon,
    permissions: permissions.dashboard.header.costs,
    path: APP_ROUTES.costs.post_back_debug.register,
  },
  {
    label: _t("Manage"),
    // icon: ManageIcon,
    permissions: permissions.manage.users,
    children: [
      {
        label: _t("Users"),
        path: APP_ROUTES.manage.users,
      },
      {
        label: _t("create_user"),
        path: APP_ROUTES.manage.users_create,
      },
      {
        label: _t("managers"),
        path: APP_ROUTES.manage.managers,
      },
      {
        label: _t("Brands"),
        path: APP_ROUTES.affiliates_programs.brands,
      },
      {
        label: _t("Notifications"),
        path: APP_ROUTES.manage.notifications.list,
      },
      // {
      //   label: _t("fraudsters"),
      //   path: APP_ROUTES.manage.fraudsters.list,
      // },
    ],
  },
  {
    label: _t("reports"),
    // icon: ReportIcon,
    permissions: permissions.reports,
    children: [
      {
        label: _t("affiliates_report"),
        path: APP_ROUTES.reports.affiliates_report,
      },
      {
        label: _t("affiliate_difference_report"),
        path: APP_ROUTES.reports.affiliates_difference_report,
      },
      {
        label: _t("affiliates_geo_report"),
        path: APP_ROUTES.reports.affiliates_geo_report,
      },
      {
        label: _t("gamers_ftd_report"),
        path: APP_ROUTES.reports.gamers_ftd_report,
      },
      {
        label: _t("gamers_report"),
        path: APP_ROUTES.reports.gamers_report,
      },
      {
        label: _t("geo_report"),
        path: APP_ROUTES.reports.geo_report,
      },
      {
        label: _t("totals_report"),
        path: APP_ROUTES.reports.totals_report,
      },
    ],
  },
  {
    label: _t("content"),
    // icon: ContentIcon,
    permissions: permissions.content.translation,
    children: [
      {
        label: _t("Faq"),
        path: APP_ROUTES.content.faq.list,
      },
      {
        label: _t("translations"),
        path: APP_ROUTES.content.translations.list,
      },
      {
        label: _t("our_testimonials"),
        path: APP_ROUTES.content.testimonials.list,
        permissions: permissions.user_permission.admin,
      },
    ],
  },
];
const SidebarNavigation = (props) => {
  return (
    <ul className="sidebar-navigation">
      {NAVIGATION_SIDEBAR(_t).map((item) => (
        <NavItem {...item} key={item.label} isCollapsed={props.isCollapsed} />
      ))}
    </ul>
  );
};

export default SidebarNavigation;
