import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocale } from "../../Locales/actions";
import { Dropdown, Menu } from "antd";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const DropdownLanguagesMenu = () => {
  const { languages } = useSelector((state) => state.locale);
  const dispatch = useDispatch();
  const languageHandler = (lang) => {
    updateLocale(lang.key)(dispatch);
  };
  return (
    <Menu onClick={languageHandler}>
      {languages &&
        Object.values(languages).map((language, index) => {
          return (
            <Menu.Item key={languages[index].code}>
              <img
                src={`https://income-front-data.s3.fr-par.scw.cloud/common/images/flags/4x3/${languages[index].code}.svg`}
                alt={"flag"}
              />
              {/*{languages[index].name}*/}
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

const LandingLanguages = () => {
  const locale = getLocale();
  const { languages } = useSelector((state) => state.locale);
  const language = Object.values(languages).filter(
    (item, index) => languages[index].code === locale
  )[0];
  return (
    <Dropdown
      overlay={<DropdownLanguagesMenu />}
      overlayClassName={"languages-overlay"}
      overlayStyle={{ zIndex: 10000 }}
      placement="bottomCenter"
      getPopupContainer={(el) => el}
    >
      <span className={"language-dropdown"}>
        <span>
          <img
            className={"language-dropdown__flag"}
            src={`https://income-front-data.s3.fr-par.scw.cloud/common/images/flags/4x3/${locale}.svg`}
            alt={"flag"}
          />
        </span>
        {/*<span>{language && language["name"]}</span>*/}
      </span>
    </Dropdown>
  );
};

export default LandingLanguages;
