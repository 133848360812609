import React from "react";
import { useParams } from "react-router-dom";
import { FaqResponse } from "../../api";
import { Faqs } from "../Faqs/Faqs";
import { _t } from "../../../Common/components/InjectIntlContext";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
};

export function FaqTitles({ data, loading }: FaqTitlesProps) {
  const { category } = useParams();
  return (
    <section className="faq custom-section custom-section--small-indent">
      <div className="container">
        <h2 className="custom-section__title">{_t(category)}</h2>
        <Faqs data={data} loading={loading} />
      </div>
    </section>
  );
}
