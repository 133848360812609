import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { useTranslation } from "../apiHooks";
import { Spin } from "antd";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const PostbackOptionsPage = () => {
  const { data, isLoading } = useTranslation({
    language: getLocale(),
    name: "faq-postback",
  });
  return (
    <section className="postback-options custom-section custom-section--small-indent">
      <div className="container">
        <h2 className="custom-section__title">{_t("postback_options")}</h2>
        <Spin spinning={isLoading}>
          <div
            dangerouslySetInnerHTML={{ __html: data?.text }}
            className="text-block"
          ></div>
        </Spin>
      </div>
    </section>
  );
};

export default PostbackOptionsPage;
