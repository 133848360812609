import React from "react";
import classNames from "classnames";
import CustomButton from "../../Common/components/CustomButton";
import { Link } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";

export type bannerSlideProps = {
  backgroundImg?: string;
  title?: string;
  subtitle?: string;
  idx?: any;
};

const BannerSlide: React.FC<bannerSlideProps> = (props) => {
  return (
    <div
      className="banner"
      style={{ backgroundImage: `url(${props.backgroundImg})` }}
    >
      {props.idx === 0 && (
        <div className="light-decor">
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
          <div className="decor-item"></div>
        </div>
      )}
      <div className="container">
        <div className="banner-content">
          {props.title && (
            <h2 className="banner-content__title">{props.title}</h2>
          )}
          <h6 className="banner-content__subtitle">{props.subtitle}</h6>
          <Link to={APP_ROUTES.login.main} className="btn btn--dark">
            {_t("get_started")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BannerSlide;
