import React, { useState } from "react";
import { useGetTotals } from "../../hooks/useGetTotals";
import { DashboardTotalItem } from "../../../../../modules/Dashboard/components/DashboardTotalItem";
import { isCurrencyField } from "../../../../../modules/Dashboard/constants";
import { CurrencyLabel } from "../../../../../modules/Common/components";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { DateRangePicker } from "../../../../../common/components";
import moment from "moment";
import { Button, Space, Spin } from "antd";
import config from "../../../../../system/config";
import { usePeriodBalance } from "../../../../../modules/Dashboard/apiHooks";

const Totals = () => {
  const options = [
    { value: "royalty", label: _t("royalty") },
    { value: "hits", label: _t("Hits") },
    { value: "hosts", label: _t("Hosts") },
    { value: "regs", label: _t("regs") },
    { value: "first_deposits", label: _t("first_deposits") },
    {
      value: "deposits",
      label: _t("deposits") + ", " + config.projectCurrency,
    },
    { value: "comppoints", label: _t("bonus") + ", " + config.projectCurrency },
    { value: "qualified_players", label: _t("Qualified players") },
    {
      value: "partner_income",
      label: _t("Partner income") + ", " + config.projectCurrency,
    },
    {
      value: "full_assured_income",
      label: _t("assured_income") + ", " + config.projectCurrency,
    },
  ];
  const [filters, setFilters] = useState({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [dates, setDates] = useState({
    from: filters.from,
    to: filters.to,
  });
  const { data } = useGetTotals({
    from: filters.from.format("YYYY-MM-DD"),
    to: filters.to.format("YYYY-MM-DD"),
  });
  const {
    data: periodBalance,
    isLoading: isPeriodLoading,
  } = usePeriodBalance();

  return (
    <div>
      <Space style={{ marginBottom: "15px" }}>
        <div style={{ maxWidth: "300px" }}>
          <DateRangePicker
            value={dates}
            onChange={(dates) => {
              setDates({
                from: dates[0],
                to: dates[1],
              });
            }}
          />
        </div>
        <Button
          size="large"
          shape="round"
          type="primary"
          onClick={() =>
            setFilters({
              ...filters,
              ...dates,
            })
          }
        >
          {_t("filter")}
        </Button>
      </Space>

      {data ? (
        <div className="dashboard-totals">
          <DashboardTotalItem
            values={[data.totals.ggr, ""]}
            titles={["ggr", ""]}
          />
          <DashboardTotalItem
            values={[data.totals.ggr_casino, data.totals.tax_casino]}
            titles={["ggr_casino", "tax_casino"]}
          />
          <DashboardTotalItem
            values={[data.totals.bets_sport, data.totals.tax_sports]}
            titles={["bets_sport", "tax_sports"]}
          />
          {!isPeriodLoading && periodBalance && (
            <DashboardTotalItem
              values={[periodBalance?.data.current_period_balance]}
              titles={["current_period_balance"]}
              description="partner_income_description"
            />
          )}
          <div className="dashboard-totals__grid">
            {options.map((column) => {
              const value = data.totals?.[column.value] || 0;
              return (
                <div key={column.value}>
                  <div className="dashboard-totals-item">
                    <p className="dashboard-totals-item__title">
                      {column.label}
                    </p>
                    <p className="dashboard-totals-item__value">
                      {isCurrencyField(column.value) ? (
                        <CurrencyLabel value={value} />
                      ) : (
                        value
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <Spin spinning />
        </div>
      )}
    </div>
  );
};

export default Totals;
