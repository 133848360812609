import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import world_geo from "@amcharts/amcharts5-geodata/worldLow";
import { currencyFormator } from "../../../../../../modules/Common/components/CurrencyLabel";

export const generateGeoChart = (
  data,
  chartID,
  setCurrentCountry,
  setIsModalOpen,
  labelTitle
) => {
  let root = am5.Root.new(chartID);
  root.setThemes([am5themes_Animated.new(root)]);

  let chart = root.container.children.push(am5map.MapChart.new(root, {}));

  let polygonSeries = chart.series.push(
    am5map.MapPolygonSeries.new(root, {
      geoJSON: world_geo,
      exclude: ["AQ"],
    })
  );
  polygonSeries.mapPolygons.template.set("fill", am5.color(0x61C1B6));

  let bubbleSeries = chart.series.push(
    am5map.MapPointSeries.new(root, {
      valueField: "value",
      calculateAggregates: true,
      polygonIdField: "id",
    })
  );

  let circleTemplate = am5.Template.new({});

  bubbleSeries.bullets.push(function (root, series, dataItem: any) {
    let container = am5.Container.new(root, {});
    let circle = container.children.push(
      am5.Circle.new(
        root,
        {
          radius: 20,
          fillOpacity: 0.7,
          fill: am5.color(0xff0000),
          cursorOverStyle: "pointer",
          tooltipText: `{label}: [bold]${currencyFormator.format(
            parseFloat(dataItem.dataContext.value)
          )}[/]`,
        },
        // @ts-ignore
        circleTemplate
      )
    );

    let countryLabel = container.children.push(
      am5.Label.new(root, {
        text: "{name}",
        paddingLeft: 5,
        populateText: true,
        fontWeight: "bold",
        fontSize: 12,
        centerY: am5.p50,
      })
    );

    circle.on("radius", function (radius) {
      countryLabel.set("x", radius);
    });

    return am5.Bullet.new(root, {
      sprite: container,
      dynamic: true,
    });
  });

  bubbleSeries.bullets.push(function (root, series, dataItem: any) {
    return am5.Bullet.new(root, {
      sprite: am5.Label.new(root, {
        text: `${currencyFormator.format(
          parseFloat(dataItem.dataContext.value)
        )}`,
        fill: am5.color(0xffffff),
        populateText: true,
        centerX: am5.p50,
        centerY: am5.p50,
        fontSize: 9,
        textAlign: "center",
      }),
      dynamic: true,
    });
  });

  // minValue and maxValue must be set for the animations to work
  bubbleSeries.set("heatRules", [
    {
      target: circleTemplate,
      dataField: "value",
      min: 30,
      max: 45,
      key: "radius",
    },
  ]);

  bubbleSeries.data.setAll(data);
  // @ts-ignore
  circleTemplate.events.on("click", function (ev) {
    // @ts-ignore
    setCurrentCountry(ev.target.dataItem?.dataContext.id);
    setIsModalOpen(true);
  });
  for (var i = 0; i < bubbleSeries.dataItems.length; i++) {
    bubbleSeries.data.setIndex(i, {
      value: data[i].value,
      id: data[i].id,
      name: data[i].name,
      label: labelTitle,
    });
  }
};
