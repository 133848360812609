import React, { useState } from "react";
import { Form, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useByInvites } from "../apiHooks";
import { CurrencyLabel } from "../../Common/components";
import { Filters } from "../../Common/components/Filters";
import { DatePicker } from "../../Common/components/DatePicker";
import moment from "moment";
import { _t } from "../../Common/components/InjectIntlContext";
import { NoData } from "../../Common/components/NoData";
import { DateRangePicker } from "../../../common/components";
import { setFormInputValue } from "../../../common/helpers";
import { useForm } from "antd/es/form/Form";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("code"),
    dataIndex: "promo",
    key: "promo",
    render: (val) => val?.code ?? val?.id,
  },
  {
    title: _t("subaffiliates_income"),
    dataIndex: "subaffiliate_income",
    key: "subaffiliate_income",
    render: (val) => <CurrencyLabel value={val} />,
  },
  {
    title: _t("your_profit"),
    dataIndex: "partner_income",
    key: "partner_income",
    render: (val) => <CurrencyLabel value={val} />,
  },
];

const useTableData = () => {
  const [filters, setFilters] = useState({
    date: { from: moment().startOf("month"), to: moment() },
  });
  const { isLoading, data } = useByInvites({
    from: filters.date.from.format("YYYY-MM-DD"),
    to: filters.date.to.format("YYYY-MM-DD"),
  } as any);

  return { setFilters, filters, isLoading, data };
};

export function ByInvitesWidget() {
  const { setFilters, filters, data, isLoading } = useTableData();
  const translatedColumns = columns(_t);
  const [form] = useForm();
  return (
    <>
      <div>
        <Filters
          onApply={setFilters}
          initialValues={filters}
          mainFilters={
            <Space size={22} direction="horizontal" align="center">
              <Form.Item name="date">
                <DateRangePicker
                  onChange={(dates) => {
                    setFormInputValue(
                      "date",
                      {
                        from: dates[0],
                        to: dates[1],
                      },
                      form
                    );
                  }}
                />
              </Form.Item>
            </Space>
          }
        />
      </div>

      <Table
        loading={isLoading}
        columns={translatedColumns}
        rowKey="promo_id"
        dataSource={data?.data?.stats}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br />
      <p>
        {_t("income_from_subaffiliates")}
        <CurrencyLabel value={data?.data?.total_partner_income} />
      </p>
      <p>
        {_t("subaffiliates_income")}
        <CurrencyLabel value={data?.data?.total_subaffiliate_income} />
      </p>
    </>
  );
}
