import React from "react";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { generatePath, useHistory } from "react-router-dom";
import { Button, Card, Divider, Spin, Switch, Typography } from "antd";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { usePostFaqStatus } from "../hooks";
import StatusLabel from "../../../../../common/components/StatusLabel/StatusLabel";

const FaqItem = ({ id, title, content, visible, category }) => {
  const faqPath = generatePath(APP_ROUTES.content.faq.edit, {
    id,
  });
  const [postFaqStatus, { isLoading: isLoadingFaq }] = usePostFaqStatus();
  const history = useHistory();
  const [updateStatus, { isLoading: isStatusLoading }] = usePostFaqStatus();

  return (
    <Card className="faq-list-item">
      <Spin spinning={isLoadingFaq}>
        <div className="faq-list-item__top">
          <Switch
            defaultChecked={!!visible}
            disabled={isStatusLoading}
            checked={!!visible}
            onChange={() =>
              updateStatus({
                id,
                type: !!visible ? "deactivate" : "activate",
              })
            }
          />
          <Spin spinning={isStatusLoading}>
            <StatusLabel
              status={visible ? 1 : 0}
              text={visible ? _t("active") : _t("disabled")}
            />
          </Spin>
          <div className="faq-list-item__title">
            {category}:<Typography.Title level={5}>{title}</Typography.Title>
          </div>
          <Button
            size="large"
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            onClick={() => history?.push(faqPath)}
            className="faq-list-item__edit"
          >
            {_t("edit")}
          </Button>
          <Button
            size="large"
            shape="round"
            icon={<DeleteOutlined />}
            onClick={() => postFaqStatus({ id, type: "delete" })}
            disabled={isLoadingFaq}
          >
            {_t("delete")}
          </Button>
        </div>
        <Divider />
        <div
          className="faq-list-item__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </Spin>
    </Card>
  );
};

export default FaqItem;
