import React, { useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const Header = (props: any) => {
  const location = useLocation();
  const navRef = useRef(null);
  useEffect(() => {
    const nav: any = navRef.current;
    if (nav) {
      nav.querySelector("a.active")?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, [location]);
  return (
    <div className="layout-header">
      {props.links && (
        <ul className="header-nav" ref={navRef}>
          {props.links.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={location.pathname === item.path && "active"}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Header;
