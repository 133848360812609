import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useNewsList } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import { Card, Spin, Typography } from "antd";
import { PostPreview } from "../components/PostPreview";
import { NavLink } from "react-router-dom";
import Meta from "antd/es/card/Meta";

const NewsList = () => {
  const locale = getLocale();
  const { data, isLoading } = useNewsList({
    language: locale,
  });
  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <section className="news-list custom-section custom-section--small-indent">
        <div className="container">
          <h2 className="custom-section__title">{_t("news")}</h2>
          <br />
          <Spin spinning={isLoading}>
            {data?.news?.map((post) => (
              <NavLink to={`/news-content/${post.url}`} key={post.id}>
                <PostPreview post={post} />
              </NavLink>
            ))}
          </Spin>
        </div>
      </section>
    </LandingTemplate>
  );
};

export default NewsList;
