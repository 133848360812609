import React from "react";
import { Select } from "antd";
import { updateLocale } from "../../../modules/Locales/actions";
import { useSelector, useDispatch } from "react-redux";
import { createOptions } from "../../helpers";

const LanguageSelect = () => {
  const { languages, locale } = useSelector((state) => state.locale);
  const dispatch = useDispatch();
  const languageHandler = (lang) => {
    updateLocale(lang)(dispatch);
  };
  return (
    <Select
      defaultValue={locale}
      className="language-select"
      options={createOptions(languages, "name", "code")}
      dropdownClassName="language-select-dropdown"
      onChange={languageHandler}
      getPopupContainer={(el) => el}
    />
  );
};

export default LanguageSelect;
