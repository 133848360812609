import React from "react";
import "./index.scss";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { renderCurrency } from "../../../modules/Dashboard/constants";
const CellSports = (props: any) => {
  return (
    <div className="cell-sports">
      <div className="cell-sports__item">
        <p className="cell-sports__title">{_t("bets_sport")}</p>
        <p className="cell-sports__value">{renderCurrency(props.bets_sport)}</p>
      </div>
      <div className="cell-sports__item">
        <p className="cell-sports__title">{_t("tax_sport")}</p>
        <p className="cell-sports__value">{renderCurrency(props.tax_sports)}</p>
      </div>
    </div>
  );
};

export default CellSports;
