import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Landing/components/Header";
import Footer from "../Landing/components/Footer";
import MainBannerSlider from "../Landing/components/MainBannerSlider";
import SocialContacts from "../Landing/components/SocialContacts";

const LandingTemplate = (props) => {
  const { children, wrapClass, mainSlider } = props;
  const location = useLocation();
  useEffect(() => {
    const anchorElement =
      location.hash && document.querySelector(location.hash);
    if (anchorElement) {
      document.querySelector(location.hash).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.hash]);

  return (
    <div className={`landing-pages ${props.className || ""}`}>
      <Header />
      {mainSlider && <MainBannerSlider />}
      <div className={`main-wrap ${wrapClass}`}>{children}</div>
      <Footer />
      <SocialContacts />
    </div>
  );
};

export default LandingTemplate;
