import axiosClient from "../../system/helpers/axiosConfig";
import { GamerProfile } from "./pages/GamerProfile";
import { responseFile } from "../../system/helpers/requestHelper";
import { getUserId } from "../../system/helpers/localstorageHelper";

export interface GeneralReportRequest {
  scope:
    | "by-days-promos"
    | "by-promos"
    | "by-days"
    | "by-days-landings"
    | "by-landings"
    | "by-days-trackers"
    | "by-days-sub-id"
    | "by-days-programs";
  user_id: number | string;
  from: string;
  to: string;
  page_size?: number;
  program_id?: number;
  project_id?: number;
  promo_id?: number;
  promos?: number[];
  tracker_id?: number;
  sub_id?: string;
  prj?: number[];
  prg?: number[];
  export?: string;
  sub_id2?: string;
}

interface GeneralReportResponse {
  models: Models;
  totals: Totals;
  total_gamers: number;
  programs?: Program[];
  promos?: Promos;
  trackers?: Tracker[];
}

interface Totals {
  ng: string;
  deposits: string;
  withdraws: string;
  cancels: string;
  comppoints: string;
  partner_income: string;
  casino_income: string;
  hold: string;
  hits: string;
  hosts: string;
  regs_all: string;
  regs: string;
  installs: string;
  first_deposits: string;
  qualified_players: string;
  gamers: string;
  rounds: string;
  bonus: string;
  first_deposits_amount?: any;
  deposits_count: number;
}

interface Models {
  current_page: number;
  data: Report[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}

export interface Report {
  date: string;
  ng: string;
  deposits: string;
  // withdraws: string;
  cancels: string;
  comppoints: string;
  partner_income: string;
  casino_income: string;
  hold: string;
  hits: string;
  hosts: string;
  regs_all: string;
  regs: string;
  installs: string;
  first_deposits: string;
  qualified_players: string;
  gamers: string;
  rounds: string;
  bonus: string;
  first_deposits_amount?: any;
  deposits_count: number;
  project?: any;
  ggr?: any;
  sports?: any;
}

interface ChartDataResponse {
  info: string[][];
  data: (number | string)[][];
}
interface Program {
  id: number;
  external_id?: any;
  name: string;
  url: string;
  description: string;
  status: string;
  sort_order: number;
  is_recommend: number;
  image: string;
  created_at: string;
  updated_at: string;
  cashbox_id: number;
  type: number;
  tournament: number;
  qs_mask?: any;
  dmp_prod?: any;
  code_name?: any;
  active: boolean;
}

interface Promos {
  [key: string]: PromoItem[];
}

interface PromoItem {
  id: number;
  program_id: number;
  project_id: number;
  partner_id: number;
  tracker_id?: number;
  template_id: number;
  sub_id: string;
  sub_id2?: string;
  landing_page_id: number;
  type: number;
  code: string;
  status: number;
  use_redirector: number;
  source_type: number;
  name: string;
  options?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  program: PromoProgram;
}

interface PromoProgram {
  id: number;
  project_id: number;
  type: string;
  options: string;
  formula: string;
  status: number;
  img?: any;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  project: Project;
  translations: Translation[];
}

interface Translation {
  id: number;
  program_id: number;
  language: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface Project {
  id: number;
  external_id?: any;
  name: string;
  url: string;
  description: string;
  status: string;
  sort_order: number;
  is_recommend: number;
  image: string;
  created_at: string;
  updated_at: string;
  cashbox_id: number;
  type: number;
  tournament: number;
  qs_mask?: any;
  dmp_prod?: any;
  code_name?: any;
}

interface Tracker {
  id: number;
  user_id: number;
  name: string;
  description?: string;
  created_at: string;
}

interface PartnerBalanceResponse {
  data: Data;
}

interface Data {
  current_period: Currentperiod;
  funds_to_pay: Currentperiod;
  payout: Currentperiod;
  profit: Currentperiod;
}

interface Currentperiod {
  value: string;
  formatted: string;
}
export const getGeneralReport = (params: GeneralReportRequest) => {
  return axiosClient
    .post<GeneralReportResponse>(
      `api/stats/partner/general/${params.scope}/${params.user_id}`,
      params
    )
    .then((r) => r.data);
};

export const getGeneralReportExport = (params: GeneralReportRequest) => {
  return axiosClient
    .post(
      `api/stats/partner/general/${params.scope}/${params.user_id}`,
      params,
      { responseType: "blob" }
    )
    .then((r) => {
      return responseFile(
        r,
        params.scope + "_" + params.from + "_" + params.to + ".xlsx"
      );
    });
};

export const getChartData = (params: GeneralReportRequest) => {
  return axiosClient.post<ChartDataResponse>("/api/stats/chart/data", params);
};

export const getPartnerBalance = (params: { user_id }) => {
  return axiosClient
    .get<PartnerBalanceResponse>("/api/stats/partner/balance", { params })
    .then((r) => r.data);
};

interface ReportByDayResponse {
  data: ReportByDay[];
}

interface ReportByDay {
  gamer_id: string;
  project_id: number;
  program_id: number;
  partner_id: number;
  labels: string;
  reg_ip: string;
  country: string;
  confirm_date: string;
  ng: number;
  comppoints: number;
  deposits: number;
  withdraws: number;
  rounds: number;
  partner_income: number;
  hold: number;
  games: string;
  login?: any;
}

export interface GetReportForDayRequest {
  user_id: string;
  date: string;
  program_id?: number;
  promo_id?: number;
  tracker_id?: number;
  sub_id?: string;
  sub_id2?: string;
  landing_id?: string;
}

export interface GetGamerProfileRequest {
  project_id: number;
  gamer_id: number;
}
interface GamerProfile {
  id: string;
  project_id: number;
  partner_id: number;
  promo_id: number;
  tracker_id: number;
  landing_id: number;
  sub_id: string;
  sub_id2: string;
  program_id: number;
  reg_ip: string;
  country: string;
  city: string;
  reg_date: string;
  confirm_date: string;
  first_deposit_date: string;
  qualification_date?: any;
  first_deposit: number;
  deposits_summary: number;
  withdraws_summary: number;
  comppoints_summary: number;
  ng_summary: number;
  lang: string;
  deposit_transaction_count: number;
  promo_code: string;
  program_name: string;
  full_income: number;
  mults: any[];
}

export interface GamerReportRequest {
  project_id: string;
  gamer_id: string;
  date_from: string;
  date_to: string;
}

interface GamerReportResponse {
  data: {
    stats: GamerStats;
    totals: GamerTotals;
  };
}

interface GamerTotals {
  ng: string;
  deposits: string;
  withdraws: string;
  comppoints: string;
  partner_income: string;
}

export interface GamerStatsItem {
  date: string;
  rounds: number;
  ng: number;
  deposits: number;
  withdraws: number;
  comppoints: number;
  partner_income: number;
  rebill?: any;
}

interface GamerStats {
  current_page: number;
  data: Array<GamerStatsItem>;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}

interface GamersResponse {
  data: {
    models: GamersModels;
    filters: GamersFilter;
  };
}

interface GamersFilter {
  rfrom: string;
  rto: string;
  group_by_promo?: any;
  non_empty: string;
  from: string;
  to: string;
  page_size: number;
  prj: any[];
  prg: any[];
  program_id?: any;
  project_id?: any;
  promo_id?: any;
  campaigns?: any;
  tracker_id?: any;
  sub_id_filter?: any;
  search?: any;
  sort?: any;
}

interface GamersModels {
  current_page: number;
  data: GamersDataItem[];
  first_page_url: string;
  from: number;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
}

interface GamersDataItem {
  gamer_id: string;
  program: string;
  ng: number;
  deposits: number;
  withdraws: number;
  comppoints: string;
  cancels: number;
  partner_income: number;
  project_id: number;
  promo_id: number;
  sub_id: string;
  reg_ip: string;
  country: string;
  city: string;
  first_deposit_date: string;
  reg_date: string;
  confirm_date: string;
  dep_transactions: number;
  full_profit: string;
  mults: string;
  promo: {
    id: number;
    code: string;
    name: string;
  };
  project: {
    id: number;
    name: string;
  };
}

export interface GamersRequest {
  from: string;
  to: string;
  rfrom: string;
  rto: string;
  promo_id?: string;
}

interface GamersTotalResponse {
  data: {
    regs: string;
    first_deposits: number;
    deposits: number;
    net_gaming: number;
    partner_income: number;
    depositers: number;
    ltv: number;
  };
}

export interface GamersChartRequest {
  from: string;
  to: string;
  rfrom: string;
  rto: string;
  chart: string;
}

export const getReportForDay = (params: GetReportForDayRequest) => {
  return axiosClient
    .post<ReportByDayResponse>("/api/stats/partner/extended-by-day", params)
    .then((r) => r.data);
};

export const getGamerProfile = (params: GetGamerProfileRequest) => {
  return axiosClient
    .get<{ data: GamerProfile }>("/api/stats/gamer/info", {
      params,
    })
    .then((r) => r.data.data);
};

export const getGamerReport = (params: GamerReportRequest) => {
  return axiosClient
    .get<GamerReportResponse>("/api/stats/gamer/stats-by-days", { params })
    .then((r) => r.data.data);
};

export const getGamersReport = (params: GamersRequest) => {
  return axiosClient
    .get<GamersResponse>("/api/stats/gamers/report", { params })
    .then((r) => r.data.data);
};
export const getGamersReportExport = (params: any) => {
  return axiosClient({
    method: "get",
    url: "/api/stats/gamers/report",
    responseType: "blob",
    params,
  }).then((r) => {
    return responseFile(
      r,
      "Gamers stats " + "_" + params.from + "_" + params.to + ".xlsx"
    );
  });
};

export const getGamersTotals = (params: GamersRequest) => {
  return axiosClient
    .get<GamersTotalResponse>("/api/stats/gamers/totals", { params })
    .then((r) => r.data.data);
};

export const getGamersCharts = (params: GamersChartRequest) => {
  return axiosClient
    .get<{
      info: string[][];
      data: (number | string)[][];
    }>("/api/stats/gamers/charts", { params })
    .then((r) => r.data);
};
export const getPromoForSelect = (params: { code?: string }) => {
  return axiosClient
    .get<{
      data: Array<{
        id: number;
        code: string;
        name: string;
      }>;
    }>("/api/promo/promo-select", { params })
    .then((r) => r.data.data);
};

interface StatsUpdateTimeResponse {
  data: string;
}

export const getStatsUpdateTime = () => {
  return axiosClient
    .get<StatsUpdateTimeResponse>("/api/stats/update-time", {})
    .then((r) => r.data);
};

export const getPeriodBalance = () => {
  return axiosClient
    .get<StatsUpdateTimeResponse>("/api/stats/partner/current-period-balance", {
      params: {
        user_id: getUserId(),
      },
    })
    .then((r) => r.data);
};
