export const APP_ROUTES = {
  login: {
    main: "/login",
    hash: "/login-by-hash",
    id: "/login-by-id",
  },
  registration: "/sign-up",
  all_components: "/all-components",
  dashboard: {
    all: "/dashboard",
    general: "/dashboard/general",
    commissions: "/dashboard/commissions",
    promo: "/dashboard/promo",
    trackers: "/dashboard/trackers",
    sub_id: "/dashboard/sub_id",
    click_id: "/dashboard/click_id",
    landings: "/dashboard/landings",
    gamers: "/dashboard/gamers",
    gamer_profile: "/dashboard/profile/:projectId/:gamerId",
    admin: "/dashboard/admin",
  },
  promo: {
    create: "/promo",
    archive: "/promo/archive",
    order: "/promo/order",
  },
  trackers: "/trackers",
  payments: "/payments",
  affiliates: "/affiliates",
  costs: {
    main: "/costs",
    post_back_debug: {
      deposit: "/costs/post_back_debug/deposit",
      first_deposit: "/costs/post_back_debug/first-deposit",
      register: "/costs/post_back_debug/register",
      qualification: "/costs/post_back_debug/qualification",
      install: "/costs/post_back_debug/install",
    },
  },
  manage: {
    users: "/users/admin/list",
    users_create: "/users/admin/create",
    user_profile: "/users/profile/:partnerId",
    managers: "/managers/admin/list",
    notifications: {
      list: "/notifications/admin/list",
      create: "/notification/admin/create",
      edit: "/notification/admin/edit/:id",
    },
    manageable_list_edit: "/manageable-list/edit/:id",
    manageable_list_create: "/manageable-list/create",
    manageable_list_add_user: "/manageable-list/:id/add/:userType",
  },
  brands: {
    index: "/brands",
    bettilt: "/brands/bettilt",
    bahsegel: "/brands/bahsegel",
    casinoslot: "/brands/casinoslot",
  },
  commissions: {
    commission: "/#commissions",
    revshare: "/commission/revshare",
    cpa: "/commission/cpa",
    subaffiliate: "/commission/subaffiliate",
    hybrid: "/commission/hybrid",
  },
  faq: {
    index: "/faq/:category",
  },
  contacts: "/#contacts",
  user: {
    main: "/user",
    other: "/user/other",
    reset_password: "/reset-password",
    restore_password: "/restore-password",
  },
  terms: "/terms",
  postback_options: "/postback-options",
  testimonials: "/testimonials",
  content: {
    faq: {
      list: "/faq/admin/list",
      create: "/faq/admin/create",
      edit: "/faq/admin/edit/:id",
    },
    translations: {
      list: "/translations/admin/list",
      show: "/translations/admin/show/:keyId",
      translation: "/translations/admin/translation/:id",
      create_key: "/translations/admin/create_key",
      import_excel: "/translations/admin/import",
    },
    testimonials: {
      list: "/testimonials/admin/list",
      create: "/testimonials/admin/create",
      edit: "/testimonials/admin/edit/:id",
    },
  },
  news: {
    list: "/news",
    show: "/news-content/:slug",
  },
  tournaments: {
    list: "/tournaments",
    show: "/tournament/:id",
  },
  reports: {
    affiliates_difference_report: "/reports/affiliates-difference-report",
    affiliates_geo_report: "/reports/affiliates-geo-report",
    affiliates_report: "/reports/affiliates-report",
    gamers_ftd_report: "/reports/gamers-ftd-report",
    gamers_report: "/reports/gamers-report",
    geo_report: "/reports/geo-report",
    totals_report: "/reports/totals",
  },
  affiliates_programs: {
    brands: "/affiliate-programs/brands",
    brand_edit: "/affiliate-programs/brands/edit/:project_id",
    landing_edit: "/affiliate-programs/brands/landing/:id",
    landing_create:
      "/affiliate-programs/brands/create-landing/:project_name/:project_id",
    program_edit: "/affiliate-programs/program/edit/:project_name/:program_id",
    program_create: "/affiliate-programs/:project_name/:project_id",
  },
  chat: {
    inner: "/chat/dialog/:id",
    list: "/chat/list",
    create: "/chat/create",
    admin: {
      inner: "/admin/chat/dialog/:id",
      list: "/admin/chat/list",
      create: "/admin/chat/create",
    },
  },
};
