import React from "react";
import ReturnAdmin from "./special/ReturnAdmin";
import { Header } from "./components";

const MainLayout = (props: any) => {
  return (
    <div className="dashboard-content">
      <ReturnAdmin />
      <Header links={props.headerLinks} />
      <div className="dashboard-content__wrap">{props.children}</div>
    </div>
  );
};

export default MainLayout;
