import React from "react";
import { useParams } from "react-router-dom";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useNewsContent } from "../apiHooks";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { Spin } from "antd";
import { PostView } from "../components/PostView";

export function NewsContent() {
  const { slug } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useNewsContent({
    language: locale,
    slug: slug,
  });

  return (
    <LandingTemplate>
      <section className="custom-section custom-section--small-indent">
        <div className="container">
          <Spin spinning={isLoading}>
            <h2>{data?.post.html_title}</h2>
            <br />
            {data && (
              <div key={data.post.id} className="news-list">
                <PostView post={data.post} />
              </div>
            )}
          </Spin>
        </div>
      </section>
    </LandingTemplate>
  );
}
