import React from "react";
import { useParams } from "react-router-dom";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useFaqsList } from "../apiHooks";
import { FaqTitles } from "../components/FaqTitles/FaqTitles";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const Faq = () => {
  const { category } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useFaqsList({
    category,
    language: locale,
  });

  return (
    <LandingTemplate>
      <FaqTitles data={data} loading={isLoading} />
    </LandingTemplate>
  );
};

export default Faq;
