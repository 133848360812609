export const API_ROUTES = {
  reports: {
    affiliates: {
      path: "api/admin/reports/affiliates-report",
      key: "API_KEY_GET_ADMIN_REPORT_AFFILIATES_REPORT",
      filename: "affiliates_report",
    },
    affiliates_geo: {
      path: "api/admin/reports/affiliates-geo-report",
      key: "API_KEY_GET_ADMIN_REPORT_AFFILIATES_GEO_REPORT",
      filename: "affiliates_geo_report",
    },
    affiliates_geo_details: {
      path: "api/admin/reports/affiliates-geo-report-details",
      key: "API_KEY_GET_ADMIN_REPORT_AFFILIATES_GEO_REPORT_DETAIL",
      filename: "affiliates_geo_report_detail",
    },
    affiliates_difference: {
      path: "api/admin/reports/affiliates-diff-report",
      key: "API_KEY_GET_ADMIN_REPORT_AFFILIATES_DIFFERENCE_REPORT",
      filename: "affiliates_difference_report",
    },
    gamers_ftd: {
      path: "api/admin/reports/gamers-ftd-report",
      key: "API_KEY_GET_ADMIN_REPORT_GAMERS_FTD_REPORT",
      filename: "gamers_ftd_report",
    },
    gamers: {
      path: "api/admin/reports/gamers-report",
      key: "API_KEY_GET_ADMIN_REPORT_GAMERS_REPORT",
      filename: "gamers_report",
    },
    geo: {
      path: "api/admin/reports/geo-chart",
      key: "API_KEY_GET_GEO_REPORT",
      filename: "geo_report",
    },
    totals: {
      path: "api/admin/reports/totals-report",
      key: "API_KEY_GET_TOTALS_REPORT",
      filename: "totals_report",
    },
    geo_details: {
      path: "api/admin/reports/geo-chart-details",
      key: "API_KEY_GET_GEO_DETAIL_REPORT",
      filename: "geo_details_report",
    },
    filters: {
      programs: {
        path: "api/admin/reports/filters/programs",
        key: "API_KEY_GET_ADMIN_REPORTS_FILTERS_PROGRAMS",
      },
      statuses: {
        path: "api/admin/reports/filters/statuses",
        key: "API_KEY_GET_ADMIN_REPORTS_FILTERS_STATUSES",
      },
      currencies: {
        path: "/api/currencies",
        key: "API_KEY_GET_ADMIN_REPORTS_FILTERS_CURRENCIES",
      },
      affiliates_geo_countries: {
        path: "api/admin/reports/affiliates-geo-country-list",
        key: "API_KEY_GET_ADMIN_REPORT_AFFILIATES_GEO_COUNTRIES",
      },
    },
  },
};
