import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlide from "./BannerSlide";
import { _t } from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";

export type mainBannerSliderProps = {};
const bannerSlides = (_t: any) => [
  {
    backgroundImg: `${config.imagesDirectory}/slide-1.jpg`,
    title: _t("slide_1_title"),
    subtitle: _t("slide_1_text"),
  },
  {
    backgroundImg: `${config.imagesDirectory}/slide-2.jpg`,
    title: _t("slide_2_title"),
    subtitle: _t("slide_2_text"),
  },
];

const slickSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  fade: true,
};

const MainBannerSlider: React.FC<mainBannerSliderProps> = () => {
  const slide = (slide, idx) => <BannerSlide {...slide} idx={idx} key={idx} />;
  return (
    <div className="main-banner">
      <Slider {...slickSettings}>{bannerSlides(_t).map(slide)}</Slider>
    </div>
  );
};

export default MainBannerSlider;
