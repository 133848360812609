import React from "react";
import { Link, generatePath } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import config from "../../../system/config";
import { ReactComponent as IconFacebook } from "../../../assets/images/outer/fb.svg";
import { ReactComponent as IconInstagram } from "../../../assets/images/outer/in.svg";
import { ReactComponent as IconTwitter } from "../../../assets/images/outer/tw.svg";
import { ReactComponent as IconYouTube } from "../../../assets/images/outer/yt.svg";
import { LanguageSelect } from "../../../common/components";

const links = (_t: any) => [
  {
    title: _t("conditions"),
    path: APP_ROUTES.terms,
  },
  {
    title: _t("postback_options"),
    path: APP_ROUTES.postback_options,
  },
  {
    title: _t("Commissions"),
    path: APP_ROUTES.commissions.commission,
    anchorLink: true,
  },
  {
    title: _t("FAQ"),
    path: generatePath(APP_ROUTES.faq.index, {
      category: "general",
    }),
  },
  {
    title: _t("testimonials"),
    path: APP_ROUTES.testimonials,
  },
  {
    title: _t("Contacts"),
    path: APP_ROUTES.contacts,
    anchorLink: true,
  },
];

const Footer = () => {
  const translatedLinks = links(_t);
  const currentTime = new Date();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top">
          <Link to="/" className="footer-logo">
            <img src={`${config.imagesDirectory}/logo.svg`} alt="" />
          </Link>
          <div className="footer-col">
            <p className="footer-col__title">
              {_t("footer_col_title_information")}
            </p>
            <ul className="footer-nav">
              {translatedLinks.map((item, idx) => (
                <li key={idx}>
                  {item.anchorLink ? (
                    <a href={item.path}>{item.title}</a>
                  ) : (
                    <Link to={item.path}>{item.title}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-col">
            <p className="footer-col__title">
              {_t("footer_col_title_follow_us")}
            </p>
            <ul className="footer-nav">
              <li>
                <a
                  href={_t(`social_link_youtube`)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconYouTube />
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href={_t(`social_link_instagram`)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconInstagram />
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href={_t(`social_link_facebook`)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconFacebook />
                  FaceBook
                </a>
              </li>
              <li>
                <a
                  href={_t(`social_link_twitter`)}
                  target="_blank"
                  rel="noreferrer"
                  className="twitter-social-link"
                >
                  <IconTwitter />
                  Twitter
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <LanguageSelect />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
