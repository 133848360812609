import React from "react";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Spin } from "antd";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

interface MailsProps {
  mailsPath: string;
  useHook: any;
  isAdmin?: boolean;
}

const Mails: React.FC<MailsProps> = ({ mailsPath, useHook, isAdmin }) => {
  const params = isAdmin ? ["inbox", { page: 1 }] : [{ page: 1 }];
  const { data, isLoading } = useHook(...params);
  const hasNewMessages = data?.data?.unread_conversations?.length > 0;
  const newMessages =
    data?.data?.unread_conversations?.length < 100
      ? data?.data?.unread_conversations?.length
      : "99+";

  return (
    <Link to={mailsPath} className="sidebar-nav-item sidebar-mails">
      <span className="sidebar-nav-item__icon">
        <MailOutlined />
        <span className="sidebar-mails__indicator">
          {" "}
          {hasNewMessages ? newMessages : 0}
        </span>
      </span>
    </Link>
  );
};

export default Mails;
