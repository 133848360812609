import React, { useEffect, useState } from "react";
import { RegistrationForm } from "../components/RegistrationForm";
import { useRegister } from "../apiHooks";
import { REGISTRATION_SUCCESS_MESSAGE } from "../constants";
import { WelcomeForm } from "../components/WelcomeForm";
import AuthTemplate from "../../Layouts/AuthTemplate";
import { _t } from "../../Common/components/InjectIntlContext";
import LandingTemplate from "../../Layouts/LandingTemplate";
import config from "../../../system/config";

export function Registration() {
  const [register, { isLoading, isSuccess, data }] = useRegister();
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      if (data.status === REGISTRATION_SUCCESS_MESSAGE) {
        setRegistered(true);
      }
    }
  }, [isSuccess]);

  return (
    <LandingTemplate className="auth-page">
      <div className="container">
        <div className="auth-page-content">
          <div className="auth-page-content__image">
            <img src={`${config.imagesDirectory}/bull_2.png`} alt="" />
          </div>
          <div className="auth-page-content__info">
            {registered ? (
              <WelcomeForm data={data} />
            ) : (
              <RegistrationForm onSubmit={register} isLoading={isLoading} />
            )}{" "}
          </div>
        </div>
      </div>
    </LandingTemplate>
  );
}
