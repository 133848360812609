import React from "react";
import { Filters } from "../../../../../../../modules/Common/components/Filters";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row, Select } from "antd";
import { DateRangePicker } from "../../../../../../../common/components";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import { setFormInputValue } from "../../../../../../../common/helpers";
import { useGetDataWithoutParams } from "../../../../../../../common/hooks";
import {
  API_GET_BRANDS_LIST,
  API_KEY_GET_BRANDS_LIST,
} from "../../../GamersFTDReport/utils";

const ReportFilters = ({ setFilters, initialValues, excelButton }) => {
  const {
    data: brandsData,
    isLoading: isBrandsLoading,
  } = useGetDataWithoutParams(API_KEY_GET_BRANDS_LIST, API_GET_BRANDS_LIST);
  const [form] = useForm();
  const projectsOptions =
    brandsData &&
    Object.keys(brandsData.projects).map((item) => ({
      label: brandsData.projects[item].name,
      value: brandsData.projects[item].id,
    }));
  return (
    <Filters
      form={form}
      onApply={setFilters}
      initialValues={initialValues}
      mainFilters={
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item name="projects" label={_t("projects")}>
              <Select
                allowClear
                mode="multiple"
                options={projectsOptions}
                loading={isBrandsLoading}
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={4} xl={2}>
            {excelButton}
          </Col>
        </Row>
      }
    />
  );
};

export default ReportFilters;
