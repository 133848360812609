import React, { useState } from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";
import classNames from "classnames";
import { Link } from "react-router-dom";

const commissionsData = (_t: any) => [
  {
    title: _t("revshare_commission"),
    image: `${config.imagesDirectory}/commission-1.png`,
    children: [
      _t("revshare_1"),
      _t("revshare_2"),
      _t("revshare_3"),
      _t("revshare_4"),
    ],
  },
  {
    title: _t("cpa_program"),
    image: `${config.imagesDirectory}/commission-2.png`,
    children: [
      _t("cpa_1"),
      _t("cpa_2"),
      _t("cpa_3"),
      // _t("cpa_4"),
      _t("cpa_5"),
      _t("cpa_6"),
      _t("cpa_7"),
    ],
  },
  {
    title: _t("subaffiliate_program"),
    image: `${config.imagesDirectory}/commission-3.png`,
    children: [
      _t("subaffiliate_1"),
      _t("subaffiliate_2"),
      _t("subaffiliate_3"),
      _t("subaffiliate_4"),
    ],
  },
  {
    title: _t("hybrid_program"),
    image: `${config.imagesDirectory}/commission-4.png`,
    children: [
      _t("hybrid_program_1_text"),
      _t("hybrid_program_2_text"),
      _t("hybrid_program_3_text"),
      // _t("hybrid_program_4_text"),
    ],
  },
];

const Commissions = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const commissions = commissionsData(_t);
  return (
    <section
      className="custom-section custom-section--small-indent"
      id="commissions"
    >
      <div className="container">
        <div className="commission">
          <ul className="commission-nav">
            {commissions.map((item, idx) => (
              <li key={idx}>
                <button
                  className={classNames({
                    active: idx === activeTab,
                  })}
                  onClick={() => setActiveTab(idx)}
                >
                  <img src={item.image} alt="" />
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
          <div className="commission-content">
            {commissions.map(
              (item, idx: number) =>
                idx === activeTab && (
                  <div className="commission-card" key={idx}>
                    <p className="commission-card__title">{item.title}</p>
                    <ul className="commission-card__items">
                      {item.children.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                    <Link
                      to={APP_ROUTES.registration}
                      className="btn btn--dark"
                    >
                      {_t("get_started")}
                    </Link>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Commissions;
