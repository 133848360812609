import { REPORT_STATE_SELECTOR } from "./utils";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { REPORT_COLUMNS_KEY, REPORT_DATE_KEY } from "./utils";
import { updateReportFilters } from "./redux/actions";

export const config = {
  columns: [
    "partner_id",
    "login",
    "deposits",
    "partner_income",
    "withdraws",
    "ng",
    "comppoints",
    "first_deposits",
    "regs",
  ],
  subtotals: ["deposits", "partner_income", "withdraws", "ng"],
  columnsKey: REPORT_COLUMNS_KEY,
  dateKey: REPORT_DATE_KEY,
  stateSelector: REPORT_STATE_SELECTOR,
  api: API_ROUTES.reports.geo.path,
  apiKey: API_ROUTES.reports.geo.key,
  fileName: API_ROUTES.reports.geo.filename,
  dispatchCallback: updateReportFilters,
};
